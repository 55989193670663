import Avatar1 from '~/components/templates/InterviewTemplate/interviewee_1.png';
import Avatar2 from '~/components/templates/InterviewTemplate/interviewee_2.png';
import Avatar3 from '~/components/templates/InterviewTemplate/interviewee_3.png';
import Avatar4 from '~/components/templates/InterviewTemplate/interviewee_4.png';
import OgImage1 from '~/components/templates/InterviewTemplate/ogp_1.png';
import OgImage2 from '~/components/templates/InterviewTemplate/ogp_2.png';
import OgImage3 from '~/components/templates/InterviewTemplate/ogp_3.png';
import OgImage4 from '~/components/templates/InterviewTemplate/ogp_4.png';

type Rank = 'A' | 'B' | 'C' | 'D' | 'E';

export type Interviewee = {
  avatarUrl: string;
  name: string;
  gender: string;
  age: number;
  household: string;
  diagnosis: {
    householdAnnualIncome: Rank;
    monthlySaving: Rank;
    assets: Rank;
    comment: string;
  };
  consultationTopic: string;
};

export type Interview = {
  id: InterviewId;
  title: string;
  categories: readonly string[];
  motivation: string;
  impression: string;
  interviewee: Interviewee;
  seoMeta: { title: string; description: string; ogImage: string };
};

export const parseInterviewId = (id: string) =>
  id in interviews ? (id as InterviewId) : null;

const interviews = {
  '1': {
    id: '1',
    title:
      '予想外のライフイベントを迎え、\n収入を踏まえた保険・教育資金・住宅購入資金等の支出の予算感を知りたい',
    motivation:
      '双子を授かるという予想外のライフイベントを迎え、いまの夫婦の収入を前提に、教育費にどれだけ予算を割けるのか？適切な住宅購入予算は？といった不安に近い疑問を抱えていた中、ライフプランのシミュレーションを含めた提案をいただいたので相談に乗ってもらうことにしました。',
    impression:
      'ライフプランをシミュレーションしてもらうことで、当初抱えていた不安はそこまで気にしなくても良いことがわかった一方で、万が一への備えが重要であることを教えていただきました。その後保険、住宅購入も相談に乗ってもらいましたが、テーマごとにいちから説明しなくてよいのでありがたく思っています。',
    categories: ['ライフプランニング', '教育資金', '保険'],
    interviewee: {
      avatarUrl: Avatar1,
      name: 'Aさん',
      gender: '男性',
      age: 32,
      household: '妻 (32歳) 子供2人 (0歳、0歳)',
      diagnosis: {
        householdAnnualIncome: 'B',
        monthlySaving: 'C',
        assets: 'D',
        comment: '無駄な支出が多いかも?',
      },
      consultationTopic:
        '自分でコントロールしにくい収入を前提とした時に、保険・教育資金・住宅購入資金といった支出の予算をどう決めていけばいいのかわからないので、アドバイスが欲しい。',
    },
    seoMeta: {
      title: 'ライフプラン前提の提案で、納得のいく保険選びに繋がった',
      description:
        'ライフプランシミュレーションで必要な保険・教育資金・住宅購入資金が明確に。不安も払拭／実際にオカネコでお金の相談をした人がどうやってお悩みを解決したのかをご紹介！',
      ogImage: OgImage1,
    },
  },
  '2': {
    id: '2',
    title:
      '資産が日本株式に偏っているので、もっと分散して安全な資産形成をしたい',
    motivation:
      'ありがたいことに、勤務している会社が上場し、その株による資産を一定金額保有しています。資産は分散したほうがいいと思っていたのですが、なかなかできないでいた中、オカネコの話を聞きまして相談することにしました。',
    impression:
      '資産運用や分散投資についてほとんど不勉強な中、基本的な理解を促進いただいた上で、外貨建て保険をご提案いただきました。実際に購入したことにより、資産の分散と、毎年一定金額の利払いを獲得できました。',
    categories: ['資産形成'],
    interviewee: {
      avatarUrl: Avatar2,
      name: 'Yさん',
      gender: '男性',
      age: 63,
      household: '妻（64歳）子供3人 (35歳、28歳、26歳)',
      diagnosis: {
        householdAnnualIncome: 'C',
        monthlySaving: 'D',
        assets: 'A',
        comment: '準備は万全！ハイレベル家計',
      },
      consultationTopic:
        '持株を持ち続けることに悩んでいる。もっと分散しておいた方がいい気がしつつもしっかり運用する気にもならず、最低限のことしかしていない。\n現状として家計には何も困っていないが、おそらく2年後に雇用延長が終了し、収入面も大きく変わるので、今のうちに見直しをしたい。',
    },
    seoMeta: {
      title: '資産運用の理解を促進してより安全な資産形成ができた',
      description:
        '日本株式に偏った資産の一部を債券投資に分散させることでより安全な資産形成に／実際にオカネコでお金の相談をした人がどうやってお悩みを解決したのかをご紹介！',
      ogImage: OgImage2,
    },
  },
  '3': {
    id: '3',
    title: '加入している保険費用が高すぎると思うので見直しをしたい',
    motivation:
      '3人目の子供の出産とマイホーム購入があり、今までライフプランニングをしたことがなかったので必要を感じて相談しました。',
    impression:
      '必要なお金、時期、起こり得るリスクなどを包括的に説明をしていただけたので1つずつ理解納得しながら進むことができ、満足する選択に導いてくれました。何かあっても生活が守れる安心感を得られた一方で、一度プランニングしたら終わりではなく、ライフステージに応じてまた相談したいと思えました。本当にありがとうございました！',
    categories: ['家計改善', '教育資金', '保険'],
    interviewee: {
      avatarUrl: Avatar3,
      name: 'Tさん',
      gender: '女性',
      age: 36,
      household: '夫 (44歳) 子供3人 (3歳、1歳、0歳)',
      diagnosis: {
        householdAnnualIncome: 'C',
        monthlySaving: 'E',
        assets: 'E',
        comment: '教育資金が不足するかも…',
      },
      consultationTopic:
        'ライフプランニングをして、家計を改善するために月に夫婦で10万円以上払っている保険については見直しをした方が良いとアドバイスをもらった。加入している保険は貯蓄型なのだが、それが果たして最適なのか、必要な保障が備わっているのか、もっと良い選択肢があるのかを確認したい。',
    },
    seoMeta: {
      title: '本当に必要な保険を納得した上で選択することができた',
      description:
        '円安によって値上がった外貨建ての保険を見直すことで保険のコストを1/20に削減／実際にオカネコでお金の相談をした人がどうやってお悩みを解決したのかをご紹介！',
      ogImage: OgImage3,
    },
  },
  '4': {
    id: '4',
    title: '自分に必要な保険を教えてほしい',
    motivation:
      '自分の将来や家族の将来を考えるタイミングで、保険は本当に必要なのかを考えたのがきっかけです。ただ、そもそも保険について何も知らなかったので、無料で、しかもオンラインで相談ができるオカネコを利用しました。',
    impression:
      '相談内容に合ったアドバイザーの方を選んでくださりとても話しやすかったです。また、私の立場に立ってどんな選択肢があるのかを教えてくださったので納得して保険の契約まで進むことができました。本当にありがとうございました。',
    categories: ['教育資金', '保険'],
    interviewee: {
      avatarUrl: Avatar4,
      name: 'Wさん',
      gender: '男性',
      age: 31,
      household: '妻 (25歳) 子供1人 (2歳)',
      diagnosis: {
        householdAnnualIncome: 'E',
        monthlySaving: 'E',
        assets: 'D',
        comment: '教育資金が不足するかも…',
      },
      consultationTopic:
        '保険に何も加入していないので、いざというときに家族を守れるようにしっかりと備えたい。ただ、自分に必要な保険がわからないので、その点を含めて一緒に考えてほしい。',
    },
    seoMeta: {
      title: '保険のプロが自分の立場に立って保険を提案してくれた',
      description:
        '死亡リスクや働けなるリスクに対して公的保険でカバーできない部分を補うための保険をご提案／実際にオカネコでお金の相談をした人がどうやってお悩みを解決したのかをご紹介！',
      ogImage: OgImage4,
    },
  },
} as const;

export type InterviewId = keyof typeof interviews;

export const useInterviewSummary = (
  id: RefOrGetterFunction<InterviewId>
): Ref<Interview> => computed(() => interviews[toValue(id)]);

export const useInterviewSummaries = (
  ids: RefOrGetterFunction<InterviewId[]>
): Ref<Interview[]> => computed(() => toValue(ids).map((id) => interviews[id]));
